<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">文章列表</div>
        <el-button type="primary" @click="createUser" class="cardTitleBtn">新建文章</el-button>
      </el-col>
      <el-col :span="24">
        <el-table :data="reportList" style="width: 100%">
          <el-table-column prop="title" label="标题" sortable></el-table-column>
          <!-- <el-table-column label="标签" sortable>
            <template slot-scope="scope">
              <span>{{scope.row.tagList[0].name}}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="是否置顶" sortable>
            <template slot-scope="scope">
              <el-switch :value="scope.row.isTop" active-color="#13ce66" disabled></el-switch>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click="getDetail(scope.row)" type="primary" size="mini">编辑</el-button>
              <el-button @click="putTag(scope.row)" type="primary" size="mini">打标签</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="选择标签" width="30%" :visible.sync="tagTypeVisible" >
        <el-form>
            <el-form-item>
                <el-select v-model="tagGuid" placeholder="请选择" clearable>
                  <el-option
                    v-for="item in tagList"
                    :key="item.guid"
                    :label="item.name"
                    :value="item.guid">
                  </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="tagTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addTag">确 定</el-button>
        </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      reportList:[],
      tagList: [],
      tagTypeVisible: false,
      tagGuid: '',
      articleGuid: ''
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getReportList()
    self.getTagList()
  },
  methods: {
    // 获取文章列表
    getReportList() {
      let self = this;
      self.$axios
        .get("/api/class-article/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.reportList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "文章列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    // 获取标签列表
    getTagList() {
      let self = this;
      self.$axios
        .get("/api/class-tag/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.tagList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "标签列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    // 编辑
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "psychologicalClassroomReportDetail", query: { guid: row.guid } });
    },
    // 新建
    createUser() {
      let self = this;
      self.$router.push({ name: "psychologicalClassroomReportDetail", query: { guid: null } });
    },
    // 打标签
    putTag(row) {
      this.articleGuid = row.guid
      if(row.tagList.length) {
        this.tagGuid = row.tagList[0].guid
      } else {
        this.tagGuid = ''
      }
      this.tagTypeVisible = true
    },
    // 确认打标签
    addTag() {
      let self = this
      let tagGuidList = []
      tagGuidList.push(self.tagGuid)
      self.$axios.post('/api/class-article/tag',{
        articleGuid: self.articleGuid,
        tagGuidList: tagGuidList
      },{
        headers: { token: self.userInfo.token }
      })
      .then(function(res) {
        self.$message({
          type: 'success',
          message: res.data.message
        })
        self.getReportList()
        self.tagTypeVisible = false
      })
      .catch(function(err) {
        console.log(err)
      })
    }
  },
};
</script>
